@import url("https://fonts.googleapis.com/css?family=Lato:400,500");
:root {
    --main-color: #527ceb;
    --main-gray: #52626b;
    --icon-gray: #919191;
}
*,
*:before,
*:after {
    box-sizing: border-box;
}
* {
    margin: 0px;
    padding: 0px;
}
.body-container {
    font: 14px "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
    margin: 0;
    padding: 0;
    font-size: 16px;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    pointer-events: auto;
    min-height: 100vh;
    /* min-width: 150px; */
    display: flex;
    align-items: flex-end;
}
p {
    margin: 0;
}
body * {
    box-sizing: border-box;
    outline: none;
}
button {
    outline: none;
    border: 0;
    padding: 0;
    background: none;
    cursor: pointer;
}
.widget-container {
    position: absolute;
    z-index: 2147483647;
    height: 100%;
    color: #333;
    font-size: 16px;
    overflow: hidden;
    min-height: 52px;
}
.main-container {
    padding-left: 2px;
    position: relative;
}
.main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.conversation-container {
    display: grid;
    grid-template-rows: 60px minmax(0, 1fr) minmax(max-content, auto);
    border-radius: 10px;
    box-shadow: 0 5px 15px 0 #00000013;
    background-color: #fff;
    width: 365px;
    margin-left: auto;
    max-height: 100%;
    min-height: 100%;
}
.conversation-container * {
    box-sizing: border-box;
}
.message-header {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    background-color: var(--main-color);
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 60px;
    font-weight: 500;
    box-shadow: 0 15px 25px -13px rgba(0, 0, 0, 0.13);
    padding: 0 0.8rem;
    overflow: hidden;
    z-index: 5;
    padding: 5px 15px;
}
.message-header img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.message-header p {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-items: center;
    margin: 0 10px;
    flex: 1 1 auto;
}
.message-header .icon {
    font-size: 2rem;
}
.message-header .title {
    font-size: 1.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.message-header {
    opacity: 0.8;
}
.online-status {
    opacity: 0.8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.message-header .close-button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    transition: background-color 0.3s ease;
    border-radius: 100%;
    width: 33px;
    height: 33px;
    padding: 0.5rem;
    justify-self: flex-end;
    fill: white;
}
.message-header button.close-button:hover {
    background-color: #ffffff59;
}
.message-content {
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
}
.messages {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 0.65rem 1rem;
    overflow-y: auto;
}
.messages-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
}
.messages-info {
    align-self: center;
    margin: 1rem 0;
}
.messages-info time {
    font-size: 12px;
    font-weight: 500;
    color: rgb(154, 154, 154);
    text-transform: uppercase;
    display: block;
    text-align: center;
}
.message {
    width: auto;
    max-width: 90%;
    display: flex;
    flex-direction: row;
    padding: 0.5rem 0;
    align-items: flex-end;
    padding-right: 18px;
    padding-left: 0;
}
.message.right {
    align-self: flex-end;
    padding-right: 0;
}
.message img.user-avatar {
    width: 30px;
    border-radius: 50%;
    margin: 5px;
}
.message.right .message-container {
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: auto;
}
.message-container {
    overflow: hidden;
    width: auto;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}
.message-info {
    margin: 0 12px;
    flex: 0 1 auto;
    padding: 0.3125rem 0;
    font-size: 10px;
    font-weight: 400;
    color: #52626b;
}
.message.right .message-body-wrapper {
    align-items: flex-end;
}
.message-body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.message-body-wrapper > :not(:last-child) {
    margin-bottom: 3px;
}
.message.right .message-body:first-of-type:last-of-type {
    border-radius: 20px;
}
.message.right .message-body:nth-child(n + 1) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}
.message.right .message-body:first-of-type {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 5px;
}
.message.right .message-body:last-of-type {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 5px;
}
.message.right .message-body-info {
    text-align: right;
}
.message .message-body:first-of-type:last-of-type {
    border-radius: 20px;
}
.message .message-body:nth-child(n + 1) {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.message .message-body:first-of-type {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 5px;
}
.message .message-body:last-of-type {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 5px;
}
.message .message-body {
    background-color: #f8f8f8;
    padding: 0.5rem 0.75rem;
    word-wrap: break-word;
    border-radius: 20px;
    max-width: 100%;
    white-space: pre-wrap;
    font-size: 0.888rem;
}
.message .message-body a {
    color: inherit;
}
.message .message-body-info {
    cursor: pointer;
    margin: 0 12px;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    padding: 0.3125rem 0;
    font-size: 12px;
    text-align: left;
    color: var(--main-gray);
}
.message.right .message-body {
    color: #ffffff;
    background-color: var(--main-color);
}
.compose {
    width: 100%;
}
.compose-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #e6ecef;
    padding-left: 12px;
    padding-right: 12px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.compose textarea {
    height: 60px;
    width: 100%;
    background-color: transparent;
    user-select: text;
    border: none;
    resize: none;
    margin-bottom: 5px;
    padding-top: 22px;
    display: block;
    font-size: 16px;
    flex: 1;
    font-family: "Lato", sans-serif !important;
}

.compose button.send,
.compose label.attachment {
    opacity: 0.9;
    color: var(--icon-gray);
    width: 35px;
    height: 35px;
    justify-self: flex-end;
    align-self: center;
}

.compose label.attachment {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.compose textarea:disabled,
.compose-container.disabled button.send,
.compose-container.disabled label.attachment {
    cursor: not-allowed;
}

.compose-container.disabled {
    opacity: 0.5;
}

.bcon-container-body {
    margin: 0;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    height: fit-content;
    padding: 2px;
    padding-top: 10px;
    min-width: 50px;
}
.bcon-welcome-message-container {
    position: relative;
    z-index: 9999;
    border-radius: 0.5rem;
    background-color: #ffffff;
    color: #333;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
    margin: 0 1rem 0.3rem;
    max-width: 300px;
}
.welcome-message-arrow-right {
    display: block;
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    right: -10px;
    bottom: 12px;
    background-color: inherit;
    transform: translateX(-6px) rotate(45deg);
    pointer-events: none;
    box-shadow: 2px -2px 2px 0 rgba(0, 0, 0, 0.1);
}
.welcome-message-arrow-left {
    display: block;
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    left: 1px;
    bottom: 12px;
    background-color: inherit;
    transform: translateX(-6px) rotate(45deg);
    pointer-events: none;
    box-shadow: 2px 2px -2px 0 rgba(0, 0, 0, 0.1);
}
.bcon-container {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
    position: relative;
}
.bcon-container button {
    margin: 0px;
    padding: 0.75rem 1rem;
    outline: none;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    box-sizing: border-box;
    position: relative;
    bottom: 0px;
    z-index: 999;
    user-select: none;
    display: flex;
    font-size: 17px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    background-color: var(--main-color);
    color: white;
    border-radius: 30px;
    transition: background-color 200ms linear 0s, transform 200ms linear 0s;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.bcon-container button span {
    text-indent: -99999px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    height: 100%;
    pointer-events: none;
    margin-right: 0.5rem;
    will-change: opacity, transform;
    opacity: 1 !important;
    transform: rotate(0deg) scale(1) !important;
    transition: opacity 80ms linear 0s, transform 160ms linear 0s;
    box-sizing: border-box;
}
.notification-badge {
    position: absolute;
    border-radius: 2rem;
    width: fit-content;
    height: fit-content;
    transform: translateY(-50%);
    background-color: red;
    color: white;
    z-index: 1000;
    padding: 0 0.5rem;
    top: 0;
    right: 0;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.conversation-away-message {
    color: #a3a3a3;
    border-top: 2px dashed #d4d4d4;
    padding: 1rem;
    text-align: center;
}
.contact-form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem 0.75rem;
    background-color: #fff;
    border: 1px solid #dedede;
    color: #333;
    border-radius: 10px;
}
.form-group {
    display: flex;
    flex-direction: column;
}
.form-group .form-label {
    font-size: 0.9rem;
    margin-bottom: 3px;
}
.form-group .form-input {
    padding: 0.5rem 0.5rem;
    box-shadow: none;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: medium;
}
.start-chat-btn {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: medium;
    font-weight: 500;
    width: 100%;
}
.message-body .image-message {
    width: 100%;
    max-width: 200px;
    object-fit: contain;
    object-position: top;
    border-radius: 12px;
}
.message-body .video-message {
    width: 100%;
    max-width: 200px;
    border-radius: 12px;
}
.message-body .audio-message {
    height: 35px;
    width: 200px;
    border-radius: 12px;
}
.message-body .document-message {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 12px;
    background-color: #00000010;
}
.message-body .document-message .filename {
    flex: 1;
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}
.message-body .document-message .download-link {
    margin-left: 10px;
    border-radius: 50%;
    padding: 5px;
    display: inline-flex;
    border: 1px solid #3b3b3b;
}
.message-body .document-message .download-icon {
    display: inline-flex;
}
.message-body .document-message .download-link.white {
    border: 1px solid white;
}
.svg-download-icon {
    width: 1em;
    height: 1em;
}
.svg-document-icon path,
.svg-document-icon polygon,
.svg-document-icon rect,
.svg-download-icon path,
.svg-download-icon polygon,
.svg-download-icon rect {
    fill: #3b3b3b;
}
.svg-document-icon circle,
.svg-download-icon circle {
    stroke: #3b3b3b;
    stroke-width: 1;
}
.svg-document-icon.white path,
.svg-document-icon.white polygon,
.svg-document-icon.white rect,
.svg-download-icon.white path,
.svg-download-icon.white polygon,
.svg-download-icon.white rect {
    fill: #fff;
}
.svg-document-icon.white circle,
.svg-download-icon.white circle {
    stroke: #fff;
}
.message-body .sticker-message {
    width: 100%;
    max-width: 100px;
    object-fit: contain;
    object-position: top;
}
.attachment-container {
    display: flex;
    padding: 15px 5px;
    align-items: center;
    border-top: 1px solid #e6ecef;
}
.attachment-container > .filename {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.media-message-wrapper {
    position: relative;
}
.download-icon-wrapper {
    transition: all 250ms;
    top: 2px;
    right: 2px;
    outline: 0;
    position: absolute;
    background: #00000050;
    border-radius: 9999px;
    display: inline-flex;
    cursor: pointer;
    padding: 3px;
}
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 0.9rem;
}
::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 0.9rem;
}
:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 0.9rem;
}
:-moz-placeholder {
    /* Firefox 18- */
    font-size: 0.9rem;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media (pointer: none), (pointer: coarse) {
    .conversation-container {
        width: 100%;
    }
    .widget-container.maximized {
        width: 100%;
        height: 100%;
    }
    .main-container.maximized {
        width: 100%;
        height: 100%;
        padding: 20px;
    }
}

* {
    scrollbar-width: thin;
    scrollbar-color: #00000033 #ffffff1a;
}
/* /Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
*::-webkit-scrollbar-track {
    background: #ffffff1a;
}
*::-webkit-scrollbar-thumb {
    background-color: #00000033;
}
